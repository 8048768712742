/* eslint-disable */
import React, { Suspense, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Auth, Hub } from 'aws-amplify';
import history from './utils/history';
import { ClipLoader } from 'react-spinners';

import './App.scss';

const Login = React.lazy(() => import('./components/pages/Login.js'));
const SideNav = React.lazy(() => import('./components/pages/SideNav.js'));
const TopNav = React.lazy(() => import('./components/pages/TopNav.js'));
const Homepage = React.lazy(() => import('./components/pages/Homepage.js'));
const Team = React.lazy(() => import('./components/pages/Team.js'));
const Account = React.lazy(() => import('./components/pages/Account.js'));
const AllLicenses = React.lazy(() => import('./components/pages/AllLicenses.js'));
const AllUsers = React.lazy(() => import('./components/pages/AllUsers.js'));
const IssueLicense = React.lazy(() => import('./components/pages/IssueLicense.js'));
const EditLicense = React.lazy(() => import('./components/pages/EditLicense.js'));

function App ({ cognitoUser, saveCognitoUser, error, setError, success, setSuccess }) {
  useEffect(() => {
    const updateUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        saveCognitoUser(user);
      } catch {
        saveCognitoUser(null);
      }
    };
    Hub.listen('auth', updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUser); // cleanup
  }, []);

  return cognitoUser ? (

    <div className='App'>

      <ConnectedRouter history={history}>

        {error &&
          <Toast className='omni-error mr-auto' onClose={() => setError('')} delay={8000} autohide>
            <Toast.Header>
              <h4>Oops! An error occurred.</h4>
            </Toast.Header>
            <Toast.Body><p className='text'>{error}</p></Toast.Body>
          </Toast>}

        {success &&
          <Toast className='omni-success mr-auto' onClose={() => setSuccess('')} delay={8000} autohide>
            <Toast.Header>
              <h4>Success!</h4>
            </Toast.Header>
            <Toast.Body><p className='text'>{success}</p></Toast.Body>
          </Toast>}

        <Suspense
          css={{ textAlign: 'center', justifyContent: 'center' }} fallback={
            <div style={{ width: '100%', textAlign: 'center' }}>
              <ClipLoader
                color='#32325d'
                loading
              />
            </div>
          }
        >
          <div className='app-wrapper'>
            <SideNav />
            <TopNav />
            <div className='page-content'>
              <Switch>
                <Route exact path='/'>
                  {cognitoUser &&
                      cognitoUser.signInUserSession &&
                      cognitoUser.signInUserSession.idToken &&
                      cognitoUser.signInUserSession.idToken.payload &&
                      cognitoUser.signInUserSession.idToken.payload['cognito:groups'] &&
                      cognitoUser.signInUserSession.idToken.payload['cognito:groups'][0]
                    ? <Redirect to='/all-licenses' />
                    : <Homepage />}
                </Route>

                <Route exact path='/' component={Homepage} />
                <Route path='/all-licenses' component={AllLicenses} />
                <Route path='/team' component={Team} />
                <Route path='/account' component={Account} />
                <Route path='/all-users' component={AllUsers} />
                <Route path='/issue-license' component={IssueLicense} />
                <Route path='/edit-license/:id' component={EditLicense} />
              </Switch>
            </div>
          </div>
        </Suspense>
      </ConnectedRouter>
    </div>
  )
    : (
      <>
        <ConnectedRouter history={history}>
          <Suspense
            css={{ textAlign: 'center', justifyContent: 'center' }} fallback={
              <div style={{ width: '100%', textAlign: 'center' }}>
                <ClipLoader
                  color='#32325d'
                  loading
                />
              </div>
            }
          >
            <Login />
          </Suspense>
        </ConnectedRouter>
      </>
    );
}

const mapStateToProps = (state) => {
  return {
    cognitoUser: state.basic.cognitoUser,
    error: state.basic.error,
    success: state.basic.success
  };
};

const mapDispatchToProps = dispatch => ({
  saveCognitoUser: (cognitoUser) => dispatch({ type: 'SAVE_COGNITO_USER', cognitoUser }),
  setError: (error) => dispatch({ type: 'SET_ERROR', error }),
  setSuccess: (success) => dispatch({ type: 'SET_SUCCESS', success })

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
