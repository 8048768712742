let apiUrl = 'https://apidev.imaginary.software/api';

const localapi = true;

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  apiUrl = 'https://api.imaginary.software/api';
} else if (localapi && window.location.host.match(/localhost/)) {
  apiUrl = 'https://apidev.imaginary.software/api';
}

export { apiUrl };
