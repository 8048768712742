import axios from 'axios';

import { apiUrl } from '../config';
import { Auth } from 'aws-amplify';

export const makeUrl = (endpoint) => {
  return apiUrl + endpoint;
};

export default async (configuration) => {
  try {
    const currentSession = await Auth.currentSession();
    const token = currentSession.accessToken.jwtToken;
    return axios.create({
      ...configuration,
      headers: {
        Authorization: `Bearer ${token}`
        // Origin: `${window.location.origin}`
      }
    }
    );
  } catch (err) {
    return axios.create({ ...configuration });
  }
};
